import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GiHouse, GiFarmTractor } from "react-icons/gi";
import { ImOffice } from "react-icons/im";
// import jobsite from '../images/jobsite.jpeg';

// const query = graphql`
//   {
//     file(relativePath: { eq: "jobsite.jpeg" }) {
//       childImageSharp {
//         fluid(maxWidth: 2208, quality: 100) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `;

const query = graphql`
  {
    first: file(relativePath: { eq: "jobsite.jpeg" }) {
      childImageSharp {
        fluid1: fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    second: file(relativePath: { eq: "dumpTruckTrailer.jpeg" }) {
      childImageSharp {
        fluid2: fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    third: file(relativePath: { eq: "excavator.jpg" }) {
      childImageSharp {
        fluid3: fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ExcavationSection = () => {
  const {
    first: {
      childImageSharp: { fluid1 },
    },
    second: {
      childImageSharp: { fluid2 },
    },
    third: {
      childImageSharp: { fluid3 },
    },
  } = useStaticQuery(query);

  return (
    <section>
      <div className="container px-5 pt-12 pb-12 mx-auto md:pb-24">
        <div className="text-center mb-12">
          <h1 className="sm:text-4xl text-2xl font-bold uppercase title-font text-gray-900 mb-4">
            Excavation
          </h1>
          <div className="w-full h-56 mb-4 overflow-hidden md:h-80 lg:h-104">
            {/* <img src={jobsite} alt="jobsite" className="object-cover object-center h-full w-full rounded-lg"/> */}
            <Image
              fluid={fluid1}
              alt="Equipment at the Jobsite"
              className="object-cover object-center h-full w-full rounded-lg"
            />
          </div>
          <p className="text-base leading-relaxed lg:w-3/4 mx-auto">
            Looking to partner with a hardworking and dependable small business
            for your next project? Look no further than Ostrom Contracting for
            all of your excavation needs. We have the equipment to take on
            almost any sort of project across residential, commercial, and
            agricultural markets. Take a look at our{" "}
            <Link className="underline" href="/projects">
              portfolio of projects
            </Link>{" "}
            we’ve completed for clients to get a better idea of what we’re
            capable of.
          </p>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5 flex-shrink-0">
              {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg> */}
              <GiHouse className="w-10 h-10" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Residential
              </h2>
              <p className="leading-relaxed text-base">
                We’ve taken on a variety of residential projects including
                digging basements, rock wall construction, land clearing,
                grading, and gravel driveways, but these just are just
                scratching the surface.{" "}
                <Link to="/contact" className="underline">
                  Contact
                </Link>{" "}
                us today to learn if we can help you with your next project.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5 flex-shrink-0">
              <ImOffice className="w-10 h-10" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Commercial
              </h2>
              <p className="leading-relaxed text-base">
                With our collection of tough equipment, we’re capable of most
                commercial projects our clients can throw at us. Our previous
                projects have ranged from grading parking lots to other odds and
                ends to help clients keep their facilities in top shape.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5 flex-shrink-0">
              <GiFarmTractor className="w-10 h-10" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Agricultural
              </h2>
              <p className="leading-relaxed text-base">
                Beyond our other commercial projects, we’ve also taken on a
                handful of agricultural excavation projects. These have included
                ditch clearing and grading projects to help operations manage
                their land better.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap mt-20 -mx-4 -mb-10 text-center">
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-56 overflow-hidden md:h-64 lg:h-96">
              <Image
                fluid={fluid2}
                alt="Dumptruck hauling equipment"
                className="object-cover object-center h-full w-full"
              />
            </div>
            <p className="leading-relaxed text-base mt-6">
              As your local Northeast Wisconsin excavation contractor, we are
              driven to deliver a personalized service no matter what type of
              project to make sure our clients are satisfied at the end of the
              day.
            </p>
          </div>
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-56 overflow-hidden md:h-64 lg:h-96">
              <Image
                fluid={fluid3}
                alt="Excavator at the jobsite"
                className="object-cover object-center h-full w-full"
              />
            </div>

            <p className="leading-relaxed text-base mt-6">
              With experience across residential, commercial, and agricultural
              excavation we have the team and the equipment ready for your next
              project.{" "}
              <Link to="/contact" className="underline">
                Contact
              </Link>{" "}
              us today to learn more about how we can help you or your business.
            </p>
            {/* <button className="lex w-56 mx-auto mt-6 text-white font-bold bg-gray-900 border-0 py-2 px-5 focus:outline-none hover:text-ocyellow rounded">Drainage Tiling Details</button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExcavationSection;
