import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ExcavationSection from "../components/excavationSection";
import DrainageTiling from "../components/drainageTilingSection";
import ServicesCTA from "../components/servicesCTA";

const Services = () => {
  return (
    <Layout>
      <SEO
        keywords={[
          `excavation`,
          `tiling`,
          `drainage tiling`,
          `agricultural drainage tile`,
          `agricultural tiling`,
          `excavation contractor`,
          `residential excavation`,
          `commercial excavation`,
          `Ostrom Contracting`,
          `Mac Ostrom`,
        ]}
        title="Services"
      />
      <ExcavationSection />
      <DrainageTiling />
      <ServicesCTA />
    </Layout>
  );
};

export default Services;
