import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { GiCorn } from "react-icons/gi";
import { FiClock } from "react-icons/fi";
import { VscBeaker } from "react-icons/vsc";

const query = graphql`
  {
    first: file(relativePath: { eq: "drainageTiling.jpg" }) {
      childImageSharp {
        fluid1: fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    second: file(relativePath: { eq: "drainageTilingExcavator.jpg" }) {
      childImageSharp {
        fluid2: fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    third: file(relativePath: { eq: "tilingSunset.jpg" }) {
      childImageSharp {
        fluid3: fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export const DrainageTiling = () => {
  const {
    first: {
      childImageSharp: { fluid1 },
    },
    second: {
      childImageSharp: { fluid2 },
    },
    third: {
      childImageSharp: { fluid3 },
    },
  } = useStaticQuery(query);

  return (
    <section>
      <div className="container px-5 py-12 mx-auto md:py-24">
        <div className="text-center mb-12">
          <h1 className="sm:text-4xl text-2xl font-bold uppercase title-font text-gray-900 mb-4">
            Drainage Tiling
          </h1>
          <div className="w-full h-56 mb-4 overflow-hidden md:h-80 lg:h-104">
            {/* <img src={DrainageTilingImg} alt="DrainageTilingImg" className="object-cover object-center h-full w-full rounded-lg"/> */}
            <Image
              fluid={fluid1}
              alt="At Work Tiling a Field"
              className="object-cover object-center h-full w-full rounded-lg"
            />
          </div>
          <p className="text-base leading-relaxed lg:w-3/4 mx-auto">
            Drainage tiling is a worthwhile investment for many agricultural
            operations, especially in Northeast Wisconsin, as it can help
            increase yields, get equipment out in the fields earlier, and result
            in better long-term soil health. Let us help you invest in the
            future productivity of your operation today.
          </p>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5 flex-shrink-0">
              {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg> */}
              <GiCorn className="w-10 h-10" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Better Yields
              </h2>
              <p className="leading-relaxed text-base">
                By enabling plant roots better access to oxygen, this results in
                stronger and healthier plants that are capable of higher yields
                and better quality.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5 flex-shrink-0">
              <FiClock className="w-10 h-10" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Timelier Field Operations
              </h2>
              <p className="leading-relaxed text-base">
                Drainage tile helps fields dry off sooner and soil with less
                moisture absorbs heat faster. This will help you get to your
                important field work earlier in the spring to benefit yields and
                help you manage your workload.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-900 text-white mb-5 flex-shrink-0">
              <VscBeaker className="w-10 h-10" />
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Better Soil Health
              </h2>
              <p className="leading-relaxed text-base">
                Drainage tile can have a lasting impact as increased oxygen in
                the soil increases microbial activity and better nitrogen
                utilization which results in better long-term productivity of
                the soil.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap mt-20 -mx-4 -mb-10 text-center">
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-56 overflow-hidden md:h-64 lg:h-96">
              <Image
                fluid={fluid2}
                alt="Excavator working in a field"
                className="object-cover object-center h-full w-full"
              />
            </div>
            <p className="leading-relaxed text-base mt-6">
              We have the team and equipment capable of taking on any sized
              drainage tiling project in an efficient manner so you can spend
              more time out in the fields each year.
            </p>
          </div>
          <div className="sm:w-1/2 mb-10 px-4">
            <div className="rounded-lg h-56 overflow-hidden md:h-64 lg:h-96">
              <Image
                fluid={fluid3}
                alt="Tractor hauling drainage tiling"
                className="object-cover object-center h-full w-full"
              />
            </div>

            <p className="leading-relaxed text-base mt-6">
              You can rest assured when you partner with us since you know we
              will work until the job is done making sure you have a
              high-quality drainage tile installation that will benefit your
              operation for years to come.
            </p>
            {/* <button className="lex w-56 mx-auto mt-6 text-white font-bold bg-gray-900 border-0 py-2 px-5 focus:outline-none hover:text-ocyellow rounded">Drainage Tiling Details</button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DrainageTiling;
