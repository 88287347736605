import React from "react";
import { Link } from "gatsby";

export const ServicesCTA = () => {
  return (
    <section>
      <div className="container px-5 pt-12 pb-24 mx-auto flex flex-wrap lg:pt-16">
        <h2 className="sm:text-3xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-2/5">
          Ready to talk more about your next project?
        </h2>
        <div className="md:w-3/5 md:pl-6">
          <p className="leading-relaxed text-base">
            Since every project we take on is unique to each client, we will
            need to discuss your project's specific requirements to determine an
            estimated budget and timeline. Let's get in touch to discuss what
            Ostrom Contracting can do for you.
          </p>
          <div className="flex md:mt-4 mt-6">
            <Link
              to="/contact"
              className="inline-flex text-white bg-gray-900 border-0 py-2 px-4 font-bold text-sm focus:outline-none transition duration-500 ease-in-out hover:text-ocyellow rounded md:text-base lg:text-lg lg:px-8"
            >
              Get a Free Quote
            </Link>
            <Link
              to="/projects"
              className="text-gray-700 text-sm hover:text-gray-900 inline-flex items-center ml-4 md:text-base lg:text-lg"
            >
              See Example Projects
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesCTA;
